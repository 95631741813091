import React from 'react';
import Box from '@webAtoms/Box/Box';
import UpNext from '@webMolecules/UpNext/UpNext';
import BlogDetailed from '@localTypes/BlogDetailed';
import * as Route from '@routes';
import LatestBlogs from '@webSections/LatestBlogs';
import BlogPost from '@webOrganisms/BlogPost/BlogPost';

export interface PostProps {
  blog: BlogDetailed;
}

const Post: React.FC<PostProps> = ({ blog }) => {
  return (
    <Box>
      <BlogPost blog={blog} />

      <LatestBlogs exclude={blog.slug} />

      <UpNext
        links={[
          { label: 'Our process', href: Route.ourProcess() },
          { label: 'Modular homes', href: Route.designs() },
        ]}
      />
    </Box>
  );
};

export default Post;
